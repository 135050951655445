const __pages_import_0__ = () => import("/src/pages/settings.vue");
const __pages_import_1__ = () => import("/src/pages/realtime.vue");
const __pages_import_2__ = () => import("/src/pages/realtime/websocket.vue");
const __pages_import_3__ = () => import("/src/pages/realtime/sse.vue");
const __pages_import_4__ = () => import("/src/pages/realtime/socketio.vue");
const __pages_import_5__ = () => import("/src/pages/realtime/mqtt.vue");
const __pages_import_6__ = () => import("/src/pages/profile.vue");
const __pages_import_7__ = () => import("/src/pages/join-team.vue");
const __pages_import_8__ = () => import("/src/pages/index.vue");
const __pages_import_9__ = () => import("/src/pages/import.vue");
const __pages_import_10__ = () => import("/src/pages/graphql.vue");
const __pages_import_11__ = () => import("/src/pages/enter.vue");
const __pages_import_12__ = () => import("/src/pages/_.vue");
const __pages_import_13__ = () => import("/src/pages/r/_id.vue");

const routes = [{"name":"settings","path":"/settings","component":__pages_import_0__,"props":true},{"name":"realtime","path":"/realtime","component":__pages_import_1__,"children":[{"name":"realtime-websocket","path":"websocket","component":__pages_import_2__,"props":true},{"name":"realtime-sse","path":"sse","component":__pages_import_3__,"props":true},{"name":"realtime-socketio","path":"socketio","component":__pages_import_4__,"props":true},{"name":"realtime-mqtt","path":"mqtt","component":__pages_import_5__,"props":true}],"props":true},{"name":"profile","path":"/profile","component":__pages_import_6__,"props":true},{"name":"join-team","path":"/join-team","component":__pages_import_7__,"props":true},{"name":"index","path":"/","component":__pages_import_8__,"props":true},{"name":"import","path":"/import","component":__pages_import_9__,"props":true},{"name":"graphql","path":"/graphql","component":__pages_import_10__,"props":true},{"name":"enter","path":"/enter","component":__pages_import_11__,"props":true,"meta":{"layout":"empty"}},{"name":"all","path":"/:all(.*)*","component":__pages_import_12__,"props":true},{"name":"r-id","path":"/r/:id","component":__pages_import_13__,"props":true}];

export default routes;