import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore"
import {
  translateToNewRESTCollection,
  translateToNewGQLCollection,
} from "@hoppscotch/data"
import { currentUser$ } from "./auth"
import {
  restCollections$,
  graphqlCollections$,
  setRESTCollections,
  setGraphqlCollections,
} from "~/newstore/collections"
import { getSettingSubject, settingsStore } from "~/newstore/settings"

type CollectionFlags = "collectionsGraphql" | "collections"

/**
 * Whether the collections are loaded. If this is set to true
 * Updates to the collections store are written into firebase.
 *
 * If you have want to update the store and not fire the store update
 * subscription, set this variable to false, do the update and then
 * set it to true
 */
let loadedRESTCollections = false

/**
 * Whether the collections are loaded. If this is set to true
 * Updates to the collections store are written into firebase.
 *
 * If you have want to update the store and not fire the store update
 * subscription, set this variable to false, do the update and then
 * set it to true
 */
let loadedGraphqlCollections = false

export async function writeCollections(
  collection: any[],
  flag: CollectionFlags
) {
  if (currentUser$.value === null)
    throw new Error("User not logged in to write collections")

  const cl = {
    updatedOn: new Date(),
    author: currentUser$.value.uid,
    author_name: currentUser$.value.displayName,
    author_image: currentUser$.value.photoURL,
    collection,
  }

  console.log("CL --> ", cl)

  try {
    // await setDoc(
    //   doc(getFirestore(), "users", currentUser$.value.uid, flag, "sync"),
    //   cl
    // )
    await saveRecord(cl.author, cl)
  } catch (e) {
    console.error("error updating", cl, e)
    throw e
  }
}
// export async function saveCollectionsPHP(){
// }
async function saveRecord(uuid: any, data: any) {
  const requestData = {
    uuid: uuid,
    collection: data,
  }

  try {
    const response = await fetch(
      "https://proxy.miqueiasdesouza.dev/collection.php?action=save",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    )

    const data = await response.json()

    if (data.success) {
      console.log("Dados inseridos com sucesso.")
    } else {
      console.error("Erro ao inserir os dados:", data.message)
    }
  } catch (error) {
    console.error("Ocorreu um erro:", error)
  }
}

async function getAllCollections(userID: any) {
  try {
    const response = await fetch(
      `https://proxy.miqueiasdesouza.dev/collection.php?action=get&uuid=${userID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    const data = await response.json()

    if (data.success) {
      // Os registros estão em data.data
      // console.log(data.data.data)
      return data.data ?? false
    } else {
      console.error(data.message)
    }
  } catch (error) {
    console.error("Ocorreu um erro:", error)
  }
}

export function initCollections() {
  const restCollSub = restCollections$.subscribe((collections) => {
    if (
      loadedRESTCollections &&
      currentUser$.value &&
      settingsStore.value.syncCollections
    ) {
      writeCollections(collections, "collections")
    }
  })

  console.log(restCollSub)

  const gqlCollSub = graphqlCollections$.subscribe((collections) => {
    if (
      loadedGraphqlCollections &&
      currentUser$.value &&
      settingsStore.value.syncCollections
    ) {
      writeCollections(collections, "collectionsGraphql")
    }
  })

  let restSnapshotStop: (() => void) | null = null
  let graphqlSnapshotStop: (() => void) | null = null

  const currentUserSub = currentUser$.subscribe((user) => {
    if (!user) {
      if (restSnapshotStop) {
        restSnapshotStop()
        restSnapshotStop = null
      }

      if (graphqlSnapshotStop) {
        graphqlSnapshotStop()
        graphqlSnapshotStop = null
      }
    } else {
      // console.log(
      //   "collection --> ",
      //   collection(getFirestore(), "users", user.uid, "collections")
      // )

      restSnapshotStop = onSnapshot(
        collection(getFirestore(), "users", user.uid, "collections"),
        async (collectionsRef) => {
          const collections: any[] = []
          const collectionToPush = await getAllCollections(user.uid)

          if (collectionToPush) {
            console.log("File System Databse")
            collections.push(collectionToPush)
          } else {
            console.log("Firebase database")
            collectionsRef.forEach((doc) => {
              const collection = doc.data()
              collection.id = doc.id
              collections.push(collection)
            })
          }

          console.log("collections --> ", collections)

          // Prevent infinite ping-pong of updates
          loadedRESTCollections = false

          // TODO: Wth is with collections[0]
          if (collections.length > 0 && settingsStore.value.syncCollections) {
            setRESTCollections(
              (collections[0].collection ?? []).map(
                translateToNewRESTCollection
              )
            )
          }

          loadedRESTCollections = true
        }
      )

      graphqlSnapshotStop = onSnapshot(
        collection(getFirestore(), "users", user.uid, "collectionsGraphql"),
        (collectionsRef) => {
          const collections: any[] = []
          collectionsRef.forEach((doc) => {
            const collection = doc.data()
            collection.id = doc.id
            collections.push(collection)
          })

          // Prevent infinite ping-pong of updates
          loadedGraphqlCollections = false

          // TODO: Wth is with collections[0]
          if (collections.length > 0 && settingsStore.value.syncCollections) {
            setGraphqlCollections(
              (collections[0].collection ?? []).map(translateToNewGQLCollection)
            )
          }

          loadedGraphqlCollections = true
        }
      )
    }
  })

  let oldSyncStatus = settingsStore.value.syncCollections

  const syncStop = getSettingSubject("syncCollections").subscribe(
    (newStatus) => {
      if (oldSyncStatus === true && newStatus === false) {
        restSnapshotStop?.()
        graphqlSnapshotStop?.()

        oldSyncStatus = newStatus
      } else if (oldSyncStatus === false && newStatus === true) {
        syncStop.unsubscribe()
        restCollSub.unsubscribe()
        gqlCollSub.unsubscribe()
        currentUserSub.unsubscribe()

        initCollections()
      }
    }
  )
}
