

import __layout_0 from '/mnt/arquivos/projetos/infinity/infinitydev/request/hoppscotch/packages/hoppscotch-app/src/layouts/default.vue'
export const layouts = {
'default': __layout_0,
'empty': () => import('/mnt/arquivos/projetos/infinity/infinitydev/request/hoppscotch/packages/hoppscotch-app/src/layouts/empty.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
